.forgotpassword-window {
  margin: auto;
  margin-top: 10%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0px;
  width: 300px;
  height: 450px;
  background: #ffffff;
  box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.15);
  border-radius: 4px;
}

.forgotpassword-head {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 25px;
  position: static;
  width: 300px;
  height: 88px;
  left: 0px;
  top: 0px;
  background: #ffffff;
  box-shadow: inset 0px -1px 0px #d9d9d9;
  flex: none;
  order: 0;
  align-self: stretch;
  flex-grow: 0;
  /* margin: 25px 0px; */
}

.forgotpassword-heading {
  font-family: sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 30px;
  line-height: 38px;
  text-align: center;
  color: #000000;
  flex: none;
  order: 0;
  flex-grow: 0;
  margin: auto;
}

.forgotpassword-form {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 25px;
}

.forgotpassword-form .heading {
  font-family: sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 20px;
  color: #242424;
  margin: 10px 0px;
}

.forgotpassword-form .message {
  font-family: sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  justify-self: left;
  color: #242424;
  text-align: left;
}

.forgotpassword-headline {
  font-family: sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 22px;
  color: #262626;
  margin: 4px 4px;
}

.input_item {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: auto;
  background: #ffffff;
  border: 1px solid #d9d9d9;
  box-sizing: border-box;
  border-radius: 2px;
  flex: none;
  order: 1;
  align-self: stretch;
  flex-grow: 0;
  margin: auto;
}

.forgotpassword-btn {
  height: 40px;
  background: #1890ff !important;
  border-radius: 2px;
  border: none;
  align-self: stretch;
}

.forgotpassword-btn:disabled {
  background: #f5f5f5 !important;
}
.forgotpassword-btn:disabled .forgotpassword-btn-label {
  color: #bfbfbf;
}

.forgotpassword-btn-label {
  font-family: sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  color: #ffffff;
  flex: none;
  order: 1;
  flex-grow: 0;
  margin: auto;
}

.cancel-btn-label {
  text-decoration: none;
  font-family: sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  color: #1890ff;
  align-self: center;
  margin-top: 15px;
}

.password-visible-btn {
  z-index: 5;
  position: absolute;
  right: 2%;
  top: 20%;
}

.credential_message {
  color: red;
  text-align: center;
  font-size: 16px;
  padding-bottom: 1%;
}

@media only screen and (hover: none) and (pointer: coarse) {
  .forgotpassword-window {
    margin-top: 35%;
  }
}
