body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.toolbar {
  background-color: rgb(255, 255, 255) !important;
  box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.15);
}
.navbar-position {
  justify-content: left;
  margin-left: 10px;
  background: #ffffff;
  width: 100%;
}

.navbar-position .nav-link {
  position: static;
  color: #262626;
}

.navbar-position .activeNow {
  color: #1890ff !important;
  border-bottom: 2px solid #1890ff !important;
  padding-bottom: 3px;
}

.toolbar .brand {
  position: static;

  font-family: sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 30px;
  color: #000000;
  margin: 0px 0px;
}

.login-button {
  margin-left: auto;
}

.login-button .user-icon {
  display: unset;
  width: 25px;
  margin-right: 10px;
}

.icon {
  width: 120px;
}

@media only screen and (hover: none) and (pointer: coarse) {
  .login-button {
    margin-left: 0px;
  }

  .login-button .user-icon {
    display: none;
  }
}

.App {
  width: 100vw;
  height: 100vh;
  background-color: white;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    -webkit-animation: App-logo-spin infinite 20s linear;
            animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@-webkit-keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

@keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

/* width */
::-webkit-scrollbar {
  width: 10px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1; 
}
 
/* Handle */
::-webkit-scrollbar-thumb {
  background: #888; 
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555; 
}
.container {
  max-width: 98% !important;
}

.blogs {
  margin-top: 5%;
  padding: 0px 40px;
}

.banner {
  margin-top: 10px;
}

.blog-section {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  min-height: 200px;
}

.blog-section .heading {
  font-family: sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  color: #000000;
  margin-bottom: 5%;
}

.blog-button {
  background: #1890ff;
  border: none;
}

.landing-page-left-content {
  font-family: sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 42px;
  vertical-align: middle;
  color: #ffffff;
  background-color: #1890ff;
  padding-top: 10% !important;
}

.landing-page-right-content {
  vertical-align: middle;
  padding: 0px !important;
}

@media only screen and (hover: none) and (pointer: coarse) {
  .banner {
    margin-top: 40px;
  }
  .blog-button {
    background: #1890ff;
    margin-bottom: 10px;
  }
  .blogs {
    margin-top: 5%;
    padding: 0px 20px;
  }
}

.image-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh; /* Full viewport height */
  width: 100vw; /* Full viewport width */
  overflow: hidden; /* Hide any overflow */
}

.center-cropped-image {
  width: 100%;
  height: 100%;
  object-fit: cover; /* Ensures the image covers the entire container */
}

.login_window {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0px;
  width: 300px;
  height: auto;
  background: #ffffff;
  box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.15);
  border-radius: 4px;
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
}

.login-head {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 15px;
  position: static;
  width: 300px;
  height: 75px;
  left: 0px;
  top: 0px;
  background: #ffffff;
  box-shadow: inset 0px -1px 0px #d9d9d9;
  flex: none;
  order: 0;
  align-self: stretch;
  flex-grow: 0;
  /* margin: 25px 0px; */
}

.login-heading {
  font-family: sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 30px;
  line-height: 38px;
  text-align: center;
  color: #000000;
  flex: none;
  order: 0;
  flex-grow: 0;
  margin: auto;
}

.login-form {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 25px;

  position: static;
  width: 300px;
  height: 320px;
  left: 0px;
  top: 113px;

  /* Inside Auto Layout */

  flex: none;
  order: 1;
  align-self: stretch;
  flex-grow: 0;
  margin: auto;
}

.login-headline {
  position: static;
  /* width: 94px; */
  height: 22px;
  left: 0px;
  top: 0px;

  /* Regular / 14px | 22px */

  font-family: sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 22px;
  /* identical to box height, or 157% */

  /* Gray / gray-9 */

  color: #262626;

  /* Inside Auto Layout */

  flex: none;
  order: 0;
  flex-grow: 0;
  margin: 0px 4px;
}
.input_item {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: auto;
  background: #ffffff;
  border: 1px solid #d9d9d9;
  box-sizing: border-box;
  border-radius: 2px;
  flex: none;
  order: 1;
  align-self: stretch;
  flex-grow: 0;
  margin: auto;
}
.login-btn:hover {
  box-shadow: 0 12px 16px 0 rgba(0, 0, 0, 0.24),
    0 17px 50px 0 rgba(0, 0, 0, 0.19);
}

.login-btn {
  height: 40px;
  background: #1890ff !important;
  border-radius: 2px;
  border: none;
  flex: none;
  order: 3;
  align-self: stretch;
  flex-grow: 0;
  margin: 25px 0px;
}

.login-btn-label {
  font-family: sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  color: #ffffff;
  flex: none;
  order: 1;
  flex-grow: 0;
  margin: auto;
}

.forgot-password-label {
  position: static;
  width: 173px;
  height: 24px;
  left: 0px;
  top: 0px;
  font-family: sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  color: #1890ff;
  flex: none;
  order: 1;
  flex-grow: 0;
  margin: 0px 8px;
  text-decoration: none;
}

.password-visible-btn {
  z-index: 5;
  position: absolute;
  right: 2%;
  top: 20%;
}

.credential_message {
  color: red;
  text-align: center;
  font-size: 16px;
  padding-bottom: 1%;
}

@media only screen and (hover: none) and (pointer: coarse) {
  .login_window {
    margin-top: 35%;
  }
}

.background-image-container {
  background-image: url(/static/media/sprayzapp-login.1ee941f4.png); 
  background-size: cover;
  background-position: center; 
  background-repeat: no-repeat; 
  height: 100vh; 
  width: 100vw;
}
.loader_background {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  /* background-color: #212529; */
  opacity: 0.5 !important;
  position: relative;
  /* pointer-events: none; */
  z-index: 1;
}
.loader,
.loader:before,
.loader:after {
  background: #3289bb;
}

.loader-login,
.loader-login:before,
.loader-login:after {
  background: #ffffff;
}

.loader,
.loader:before,
.loader:after {
  -webkit-animation: load1 1s infinite ease-in-out;
  animation: load1 1s infinite ease-in-out;
  width: 1em;
  height: 4em;
}
.loader {
  text-indent: -9999em;
  margin: auto auto;
  /* position: absolute; */
  top: 50%;
  left: 50%;
  font-size: 11px;
  -webkit-transform: translateZ(0);
  transform: translateZ(0);
  -webkit-animation-delay: -0.16s;
  animation-delay: -0.16s;
  z-index: 1;
}
.loader:before,
.loader:after {
  position: absolute;
  top: 0;
  content: "";
}
.loader:before {
  left: -1.5em;
  -webkit-animation-delay: -0.32s;
  animation-delay: -0.32s;
}
.loader:after {
  left: 1.5em;
}
@-webkit-keyframes load1 {
  0%,
  80%,
  100% {
    box-shadow: 0 0;
    height: 4em;
  }
  40% {
    box-shadow: 0 -2em;
    height: 5em;
  }
}
@keyframes load1 {
  0%,
  80%,
  100% {
    box-shadow: 0 0;
    height: 4em;
  }
  40% {
    box-shadow: 0 -2em;
    height: 5em;
  }
}

.users_list_view {
  margin: auto auto;
  margin-top: 5%;
}
.user_view {
  margin: auto auto;
}
.coloumn {
  box-shadow: 0px 0px 2px rgb(0 0 0 / 10%);
  padding-top: 10px;
  padding-bottom: 10px;
  text-align: center;
}

.page-layout {
  padding-top: 5%;
}
.left-side-layout {
  padding-top: 20%;
}
.title-card {
  position: static;
  font-family: sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 30px;
  line-height: 38px;
  /* identical to box height, or 127% */

  color: #000000;

  /* Inside Auto Layout */

  flex: none;
  order: 0;
  flex-grow: 0;
  margin: 0px 10px;
}
.no-data-found {
  text-align: center;
}
.nav-icon.active {
  background-color: #e6f7ff !important;
  width: 100%;
  color: #1890ff !important;
}
.nav-icon {
  cursor: pointer;
  float: right;
  color: #262626 !important;
}
.nav-icon .label {
  cursor: pointer;
  float: right;
}
.button-row {
  flex-direction: row-reverse;
}
.loader-custom {
  width: auto !important;
  height: auto !important;
}

.page-top-margin {
  margin-top: 8% !important;
}

.top-buttons-div {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: flex-start;

  /* Inside Auto Layout */

  flex: none;
  order: 0;
  flex-grow: 1;
  margin: 20px 0px;
}

.user-table-row:hover {
  background-color: #e9efef;
  cursor: pointer;
}

.delete_icon {
  padding-left: 20%;
  padding-right: 20%;
}
.message {
  text-align: center;
}
.row-style {
  padding-top: 1%;
}
.buttons-style {
  justify-content: center;
}

.input_button {
  background-color: #1890ff !important;
  border: none;
}

.table_row {
  text-align: center;
}
.table_data {
  padding-top: 10px;
  padding-bottom: 10px;
  text-align: center;
}
.user_modal {
  text-align: center;
}
.input_button {
  background-color: green;
  float: right;
  font-size: 16px;
  width: 100px;
  /* padding: unset; */
  color: white;
  margin: 1% 1% 1% 1%;
}

.user-form {
  padding: 10%;
}
.dropdown-list {
  background-color: white !important;
  width: 100% !important;
}
.user-modal-size {
  max-width: 50vw;
}

.button-cell {
  display: flex;
  flex-direction: row;
  align-items: center;

  flex: none;
  order: 5;
  flex-grow: 0;
  margin-top: 10%;
}

.button-cell .buttons {
  position: relative;
  width: 20px;
  margin: 5px;
}

.active-btn-and-checkbox-cell {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: 10%;
}

.active-btn-and-checkbox-cell .label {
  padding-left: 10%;
}

.custom-size-button{
  width: 200px !important;
}
.user-button,
.active-button,
.inactive-button {
  min-width: -webkit-max-content;
  min-width: max-content;
  padding: 5px 16px;
  font-size: 14px;
  border: 1px solid #d9d9d9;
  box-sizing: border-box;
  border-radius: 2px;
  margin-left: 1%;
  margin-top: 1%;
  background-color: #ffffff !important;
  color: #000000 !important;
  padding: 5px 16px;
}
.user-button:disabled {
  font-size: 14px;
  border: 1px solid #d9d9d9;
  box-sizing: border-box;
  border-radius: 2px;
  margin-left: 1%;
  margin-top: 1%;
  background-color: #f5f5f5 !important;
  color: #000000 !important;
}
.user-button:hover {
  background-color: #1890ff !important;
  border: 1px solid #1890ff;
  color: white !important;
}
.user-button .labels {
  margin: 1px 8px;
  cursor: pointer;
}
.top-buttons-div {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: flex-start;

  /* Inside Auto Layout */

  flex: none;
  order: 0;
  flex-grow: 1;
  margin: 20px 0px;
}

.top-buttons-div-left {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;

  /* Inside Auto Layout */

  flex: none;
  order: 0;
  flex-grow: 1;
  margin: 20px 0px;
}

.table-fields-heading {
  display: flex;
  flex-direction: row;
  align-items: center;
  grid-gap: 10px;
  gap: 10px;
  
  position: static;
  width: auto;
  height: auto;
  flex: none;
  order: 1;
  flex-grow: 1;
}

.table-fields-labels {
  position: static;
  font-family: sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 22px;
}

.contact-table-icons{
  height: 20px;
}

.sorting-div {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  /* padding: 0px; */

  /* position: static;
  width: 10px;
  height: 18px;
  left: 109px;
  top: 18px; */

  /* Inside Auto Layout */

  /* flex: none;
  order: 1;
  flex-grow: 0;
  margin: 0px 8px; */
}

.sorting-btns {
  position: static;
  width: 10px;
  height: 10px;

  /* Inside Auto Layout */

  flex: none;
  order: 1;
  flex-grow: 0;
  margin: 0px 0px;
}

.table {
  border: 1px solid #f0f0f0;
  border-radius: 3px;
  border-collapse: unset;
  padding: 0px;
  border-spacing: 0px;
}

.table-heading {
  background: #fafafa;
}

.table-cells-content {
  padding: 0.5rem 0.5rem;
}
.tooltip {
  width: 15px;
  height: 15px;
  vertical-align: top;
}

.active-button {
  border: 1px solid #52c41a;
  background-color: #f6ffed !important;
}

.inactive-button {
  border: 1px solid #ffbb96;
  background-color: #fff2e8 !important;
}

.active-button .labels {
  margin: 1px 8px;
  color: #52c41a;
}

.active-button .labels:hover {
  cursor: pointer;
}

.active-button:hover {
  border: 1px solid #52c41a;
  background-color: #ffffff !important;
}

.inactive-button:hover {
  border: 1px solid #ffbb96;
  background-color: #ffffff !important;
}

.inactive-button .labels {
  margin: 1px 8px;
  color: #fa541c;
}
.inactive-button .labels:hover {
  cursor: pointer;
}

.page-item-boxes {
  margin: 0px 5px;
}
.page-item-contents {
  border-color: #d9d9d9 !important;
  color: black;
}
.active-item .page-item-contents {
  border-color: #1890ff !important;
  background-color: white !important;
  color: #1890ff !important;
}

.page-top-margin {
  margin-top: 10%;
}

.washType-table-row:hover {
  background-color: #e9efef;
  cursor: pointer;
}
.account_page{
    width: 100vw;
    height: 100vh;
    background-color: darkgray;
}
.tab-padding{
    padding: 3%;
}
.modal-size{
    max-width: 80vw;
}
.middle-content-top-margin{
    margin-top: 5%;
}
.info-section{
    /* padding-top: 2%; */
    margin: 0px 10px;   
}
.input-field{
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 8px 12px;

    position: static;
    width: 100%;
    height: 40px;
    left: 0px;
    top: 30px;

    /* Gray / gray-1 */

    background: #FFFFFF;
    /* Gray / gray-5 */

    border: 1px solid #D9D9D9;
    box-sizing: border-box;
    border-radius: 2px;

    /* Inside Auto Layout */

    flex: none;
    order: 1;
    align-self: stretch;
    flex-grow: 0;
    margin: 0px 0px;
}
.account-list-section{
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    position: static;
    /* Gray / gray-1 */

    background: #FFFFFF;
    /* Gray / gray-5 */
    /* Inside Auto Layout */

    flex: none;
    order: 0;
    flex-grow: 0;
}
.account-list-section-border{
    margin-top: 4%;
    border: 1px solid #D9D9D9;
    box-sizing: border-box;
    border-radius: 2px;
}
.account-list-row{
    width: 100%;
    justify-content: center;
}
.account-list-button{
    justify-content: center !important;
}
.search-bar{
    width: 250px;
    height: 40px;
}
.input_item {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: auto;
    background: #ffffff;
    border: 1px solid #d9d9d9;
    box-sizing: border-box;
    border-radius: 2px;
    flex: none;
    order: 1;
    align-self: stretch;
    flex-grow: 0;
    margin: auto;
  }
.row-margin{
    margin: 10px 0px;
}
.acc-table-row:hover{
    background-color:#e9efef;
    cursor: pointer;
}
.custom-row{
    
    padding: 10px;
    background: #FAFAFA;
    border: 1px solid #F0F0F0;
    margin: 25px 0px;
}

.custom-button-width{
    width: 90px !important;
    padding: 5px !important;
}

.series-button{
    margin-right: 1%;
    margin-left: 1%;
}

.custom-tab {
    display: inline-block;
    margin-right: 5px; /* Adjust as needed for spacing */
}

.nav-tabs {
    white-space: nowrap;
    overflow-x: auto;
    border: none !important;
    margin-bottom: 0px !important;
}

.nav-tabs .nav-item {
    flex-shrink: 0;
}
.nav-link.active {
    color: #0d6efd !important;
}
.nav-link{
    color: black;
    border: none !important;
}

.custom-tabs-container .nav-link.active {
    border: none !important;
    border-bottom: 2px solid #1890ff !important;
}

.custom-tabs-container .custom-tab-link {
    color: #000;
}

.tab-navigation {
    display: flex;
    justify-content: space-between;
    padding: 10px;
}

.acc-table-row {
    padding: 10px 15px;
}

.mb-3 {
    margin-bottom: 1rem;
}

.align-items-center {
    align-items: center;
}

.buttons-style .input_button {
    margin: 5px;
}

.loader-custom {
    position: absolute;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%);
}

.invoices_list_view {
  margin: auto auto;
  margin-top: 5%;
}
.invoices_view {
  margin: auto auto;
}
.coloumn {
  box-shadow: 0px 0px 2px rgb(0 0 0 / 10%);
  padding-top: 10px;
  padding-bottom: 10px;
  text-align: center;
}

.page-layout {
  padding-top: 5%;
}
.left-side-layout {
  padding-top: 20%;
}
.title-card {
  position: static;
  font-family: sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 30px;
  line-height: 38px;
  /* identical to box height, or 127% */

  color: #000000;

  /* Inside Auto Layout */

  flex: none;
  order: 0;
  flex-grow: 0;
  margin: 0px 10px;
}
.no-data-found {
  text-align: center;
}
.nav-icon.active {
  background-color: #e6f7ff !important;
  width: 100%;
  color: #1890ff !important;
}
.nav-icon {
  cursor: pointer;
  float: right;
  color: #262626 !important;
}
.nav-icon .label {
  cursor: pointer;
  float: right;
}
.button-row {
  flex-direction: row-reverse;
}
.loader-custom {
  width: auto !important;
  height: auto !important;
}

.page-top-margin {
  margin-top: 8% !important;
}

.top-buttons-div {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: flex-start;

  /* Inside Auto Layout */

  flex: none;
  order: 0;
  flex-grow: 1;
  margin: 20px 0px;
}

.invoice-table-row:hover {
  background-color: #e9efef;
  cursor: pointer;
}

a.disabled {
  pointer-events: none !important; 
  cursor: default !important;
  color: #b3b3b3;
}

.font-bold {
 font-weight: bold;
}
.emptyFieldError{
  border: 1px solid red;
}
.forgotpassword-window {
  margin: auto;
  margin-top: 10%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0px;
  width: 300px;
  height: 450px;
  background: #ffffff;
  box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.15);
  border-radius: 4px;
}

.forgotpassword-head {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 25px;
  position: static;
  width: 300px;
  height: 88px;
  left: 0px;
  top: 0px;
  background: #ffffff;
  box-shadow: inset 0px -1px 0px #d9d9d9;
  flex: none;
  order: 0;
  align-self: stretch;
  flex-grow: 0;
  /* margin: 25px 0px; */
}

.forgotpassword-heading {
  font-family: sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 30px;
  line-height: 38px;
  text-align: center;
  color: #000000;
  flex: none;
  order: 0;
  flex-grow: 0;
  margin: auto;
}

.forgotpassword-form {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 25px;
}

.forgotpassword-form .heading {
  font-family: sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 20px;
  color: #242424;
  margin: 10px 0px;
}

.forgotpassword-form .message {
  font-family: sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  justify-self: left;
  color: #242424;
  text-align: left;
}

.forgotpassword-headline {
  font-family: sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 22px;
  color: #262626;
  margin: 4px 4px;
}

.input_item {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: auto;
  background: #ffffff;
  border: 1px solid #d9d9d9;
  box-sizing: border-box;
  border-radius: 2px;
  flex: none;
  order: 1;
  align-self: stretch;
  flex-grow: 0;
  margin: auto;
}

.forgotpassword-btn {
  height: 40px;
  background: #1890ff !important;
  border-radius: 2px;
  border: none;
  align-self: stretch;
}

.forgotpassword-btn:disabled {
  background: #f5f5f5 !important;
}
.forgotpassword-btn:disabled .forgotpassword-btn-label {
  color: #bfbfbf;
}

.forgotpassword-btn-label {
  font-family: sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  color: #ffffff;
  flex: none;
  order: 1;
  flex-grow: 0;
  margin: auto;
}

.cancel-btn-label {
  text-decoration: none;
  font-family: sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  color: #1890ff;
  align-self: center;
  margin-top: 15px;
}

.password-visible-btn {
  z-index: 5;
  position: absolute;
  right: 2%;
  top: 20%;
}

.credential_message {
  color: red;
  text-align: center;
  font-size: 16px;
  padding-bottom: 1%;
}

@media only screen and (hover: none) and (pointer: coarse) {
  .forgotpassword-window {
    margin-top: 35%;
  }
}

.col-right-space{
    
}
.custom-width{
    width:265px !important;
    height: 40px !important;
}
.report-page-top-margin{
    margin-top: 5%;
}
.button-margin{
    display: flex;
    flex-direction: row;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    align-self: flex-end;
    padding: 15px 5px;
}
.date-field-width{
    width:90% !important;
    margin-left: 2%;
}
.right-download-button{
    display: flex;
    flex-direction: row-reverse;
    padding: 5px 15px;
}
.custom-width {
  width: 265px !important;
  height: 40px !important;
}
.button-margin {
  display: flex;
  flex-direction: row;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  align-self: flex-end;
  padding: 15px 5px;
}

.right-download-button {
  display: flex;
  flex-direction: row-reverse;
  padding: 5px 15px;
}

.washInventory-buttons-div {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: flex-start;
}

.washInventory-buttons-div .user-button {
  margin-right: 10px;
}

.dateRange {
  width: 90%;
  height: 2.4rem;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  padding: 1rem;
}

.dateRangeError {
  width: 90%;
  height: 2.4rem;
  border: 1px solid red;
  border-radius: 0.25rem;
  padding: 1rem;
}

.washinventory-table-row:hover {
  background-color: #e9efef;
  cursor: pointer;
}

.washinventory-view {
  margin: auto auto;
}

.modalBody{
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 8px;
  grid-gap: 25px;
  gap: 25px;
}

.modalBulkEdit {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 10px;
  grid-gap: 25px;
  gap: 25px;
  border: none !important;
  
  position: fixed;
  width: -webkit-min-content;
  width: min-content;
  height: 482px;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  
  margin: auto; 
  
  background: #FFFFFF;
  
  box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.15);
  border-radius: 4px;
}


.modalButton{
display: flex;
flex-direction: column;
align-items: center;
padding: 8px 16px;
grid-gap: 8px;
gap: 8px;

width: 250px;
height: 40px;

/* Daybreak Blue / blue-6 */

background: #1890FF;
border-radius: 2px;

/* Inside auto layout */

flex: none;
order: 4;
align-self: stretch;
flex-grow: 0;
  }


.form-container{
    padding: 10px 15px;
    justify-content: center;
}
.form-align-center{
    justify-content: center;
}
.special-instructions{
    /* background-color: red; */
    min-height: -webkit-min-content;
    min-height: min-content;
    border: 2px dotted black;
    padding: 0px 0px;
    margin: auto;
}

.modal-border{
  border: none !important;
}

.dateRange {
  width: 91%;
  height: 2.4rem;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  padding: 1rem;
  margin-left: 4%;
}

.dateRangeError {
  width: 91%;
  height: 2.4rem;
  border: 1px solid red;
  border-radius: 0.25rem;
  padding: 1rem;
  margin-left: 4%;
}
