.custom-width {
  width: 265px !important;
  height: 40px !important;
}
.button-margin {
  display: flex;
  flex-direction: row;
  height: fit-content;
  align-self: flex-end;
  padding: 15px 5px;
}

.right-download-button {
  display: flex;
  flex-direction: row-reverse;
  padding: 5px 15px;
}

.washInventory-buttons-div {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: flex-start;
}

.washInventory-buttons-div .user-button {
  margin-right: 10px;
}

.dateRange {
  width: 90%;
  height: 2.4rem;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  padding: 1rem;
}

.dateRangeError {
  width: 90%;
  height: 2.4rem;
  border: 1px solid red;
  border-radius: 0.25rem;
  padding: 1rem;
}

.washinventory-table-row:hover {
  background-color: #e9efef;
  cursor: pointer;
}

.washinventory-view {
  margin: auto auto;
}
