.custom-size-button{
  width: 200px !important;
}
.user-button,
.active-button,
.inactive-button {
  min-width: max-content;
  padding: 5px 16px;
  font-size: 14px;
  border: 1px solid #d9d9d9;
  box-sizing: border-box;
  border-radius: 2px;
  margin-left: 1%;
  margin-top: 1%;
  background-color: #ffffff !important;
  color: #000000 !important;
  padding: 5px 16px;
}
.user-button:disabled {
  font-size: 14px;
  border: 1px solid #d9d9d9;
  box-sizing: border-box;
  border-radius: 2px;
  margin-left: 1%;
  margin-top: 1%;
  background-color: #f5f5f5 !important;
  color: #000000 !important;
}
.user-button:hover {
  background-color: #1890ff !important;
  border: 1px solid #1890ff;
  color: white !important;
}
.user-button .labels {
  margin: 1px 8px;
  cursor: pointer;
}
.top-buttons-div {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: flex-start;

  /* Inside Auto Layout */

  flex: none;
  order: 0;
  flex-grow: 1;
  margin: 20px 0px;
}

.top-buttons-div-left {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;

  /* Inside Auto Layout */

  flex: none;
  order: 0;
  flex-grow: 1;
  margin: 20px 0px;
}

.table-fields-heading {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 10px;
  
  position: static;
  width: auto;
  height: auto;
  flex: none;
  order: 1;
  flex-grow: 1;
}

.table-fields-labels {
  position: static;
  font-family: sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 22px;
}

.contact-table-icons{
  height: 20px;
}

.sorting-div {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  /* padding: 0px; */

  /* position: static;
  width: 10px;
  height: 18px;
  left: 109px;
  top: 18px; */

  /* Inside Auto Layout */

  /* flex: none;
  order: 1;
  flex-grow: 0;
  margin: 0px 8px; */
}

.sorting-btns {
  position: static;
  width: 10px;
  height: 10px;

  /* Inside Auto Layout */

  flex: none;
  order: 1;
  flex-grow: 0;
  margin: 0px 0px;
}

.table {
  border: 1px solid #f0f0f0;
  border-radius: 3px;
  border-collapse: unset;
  padding: 0px;
  border-spacing: 0px;
}

.table-heading {
  background: #fafafa;
}

.table-cells-content {
  padding: 0.5rem 0.5rem;
}
.tooltip {
  width: 15px;
  height: 15px;
  vertical-align: top;
}

.active-button {
  border: 1px solid #52c41a;
  background-color: #f6ffed !important;
}

.inactive-button {
  border: 1px solid #ffbb96;
  background-color: #fff2e8 !important;
}

.active-button .labels {
  margin: 1px 8px;
  color: #52c41a;
}

.active-button .labels:hover {
  cursor: pointer;
}

.active-button:hover {
  border: 1px solid #52c41a;
  background-color: #ffffff !important;
}

.inactive-button:hover {
  border: 1px solid #ffbb96;
  background-color: #ffffff !important;
}

.inactive-button .labels {
  margin: 1px 8px;
  color: #fa541c;
}
.inactive-button .labels:hover {
  cursor: pointer;
}
