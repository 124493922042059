.delete_icon {
  padding-left: 20%;
  padding-right: 20%;
}
.message {
  text-align: center;
}
.row-style {
  padding-top: 1%;
}
.buttons-style {
  justify-content: center;
}

.input_button {
  background-color: #1890ff !important;
  border: none;
}
