.users_list_view {
  margin: auto auto;
  margin-top: 5%;
}
.user_view {
  margin: auto auto;
}
.coloumn {
  box-shadow: 0px 0px 2px rgb(0 0 0 / 10%);
  padding-top: 10px;
  padding-bottom: 10px;
  text-align: center;
}

.page-layout {
  padding-top: 5%;
}
.left-side-layout {
  padding-top: 20%;
}
.title-card {
  position: static;
  font-family: sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 30px;
  line-height: 38px;
  /* identical to box height, or 127% */

  color: #000000;

  /* Inside Auto Layout */

  flex: none;
  order: 0;
  flex-grow: 0;
  margin: 0px 10px;
}
.no-data-found {
  text-align: center;
}
.nav-icon.active {
  background-color: #e6f7ff !important;
  width: 100%;
  color: #1890ff !important;
}
.nav-icon {
  cursor: pointer;
  float: right;
  color: #262626 !important;
}
.nav-icon .label {
  cursor: pointer;
  float: right;
}
.button-row {
  flex-direction: row-reverse;
}
.loader-custom {
  width: auto !important;
  height: auto !important;
}

.page-top-margin {
  margin-top: 8% !important;
}

.top-buttons-div {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: flex-start;

  /* Inside Auto Layout */

  flex: none;
  order: 0;
  flex-grow: 1;
  margin: 20px 0px;
}

.user-table-row:hover {
  background-color: #e9efef;
  cursor: pointer;
}
