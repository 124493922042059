.login_window {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0px;
  width: 300px;
  height: auto;
  background: #ffffff;
  box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.15);
  border-radius: 4px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.login-head {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 15px;
  position: static;
  width: 300px;
  height: 75px;
  left: 0px;
  top: 0px;
  background: #ffffff;
  box-shadow: inset 0px -1px 0px #d9d9d9;
  flex: none;
  order: 0;
  align-self: stretch;
  flex-grow: 0;
  /* margin: 25px 0px; */
}

.login-heading {
  font-family: sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 30px;
  line-height: 38px;
  text-align: center;
  color: #000000;
  flex: none;
  order: 0;
  flex-grow: 0;
  margin: auto;
}

.login-form {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 25px;

  position: static;
  width: 300px;
  height: 320px;
  left: 0px;
  top: 113px;

  /* Inside Auto Layout */

  flex: none;
  order: 1;
  align-self: stretch;
  flex-grow: 0;
  margin: auto;
}

.login-headline {
  position: static;
  /* width: 94px; */
  height: 22px;
  left: 0px;
  top: 0px;

  /* Regular / 14px | 22px */

  font-family: sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 22px;
  /* identical to box height, or 157% */

  /* Gray / gray-9 */

  color: #262626;

  /* Inside Auto Layout */

  flex: none;
  order: 0;
  flex-grow: 0;
  margin: 0px 4px;
}
.input_item {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: auto;
  background: #ffffff;
  border: 1px solid #d9d9d9;
  box-sizing: border-box;
  border-radius: 2px;
  flex: none;
  order: 1;
  align-self: stretch;
  flex-grow: 0;
  margin: auto;
}
.login-btn:hover {
  box-shadow: 0 12px 16px 0 rgba(0, 0, 0, 0.24),
    0 17px 50px 0 rgba(0, 0, 0, 0.19);
}

.login-btn {
  height: 40px;
  background: #1890ff !important;
  border-radius: 2px;
  border: none;
  flex: none;
  order: 3;
  align-self: stretch;
  flex-grow: 0;
  margin: 25px 0px;
}

.login-btn-label {
  font-family: sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  color: #ffffff;
  flex: none;
  order: 1;
  flex-grow: 0;
  margin: auto;
}

.forgot-password-label {
  position: static;
  width: 173px;
  height: 24px;
  left: 0px;
  top: 0px;
  font-family: sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  color: #1890ff;
  flex: none;
  order: 1;
  flex-grow: 0;
  margin: 0px 8px;
  text-decoration: none;
}

.password-visible-btn {
  z-index: 5;
  position: absolute;
  right: 2%;
  top: 20%;
}

.credential_message {
  color: red;
  text-align: center;
  font-size: 16px;
  padding-bottom: 1%;
}

@media only screen and (hover: none) and (pointer: coarse) {
  .login_window {
    margin-top: 35%;
  }
}

.background-image-container {
  background-image: url("../../assets/images/sprayzapp-login.png"); 
  background-size: cover;
  background-position: center; 
  background-repeat: no-repeat; 
  height: 100vh; 
  width: 100vw;
}