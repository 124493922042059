.col-right-space{
    
}
.custom-width{
    width:265px !important;
    height: 40px !important;
}
.report-page-top-margin{
    margin-top: 5%;
}
.button-margin{
    display: flex;
    flex-direction: row;
    height: fit-content;
    align-self: flex-end;
    padding: 15px 5px;
}
.date-field-width{
    width:90% !important;
    margin-left: 2%;
}
.right-download-button{
    display: flex;
    flex-direction: row-reverse;
    padding: 5px 15px;
}