.modalBody{
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 8px;
  gap: 25px;
}

.modalBulkEdit {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 10px;
  gap: 25px;
  border: none !important;
  
  position: fixed;
  width: min-content;
  height: 482px;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  
  margin: auto; 
  
  background: #FFFFFF;
  
  box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.15);
  border-radius: 4px;
}


.modalButton{
display: flex;
flex-direction: column;
align-items: center;
padding: 8px 16px;
gap: 8px;

width: 250px;
height: 40px;

/* Daybreak Blue / blue-6 */

background: #1890FF;
border-radius: 2px;

/* Inside auto layout */

flex: none;
order: 4;
align-self: stretch;
flex-grow: 0;
  }


.form-container{
    padding: 10px 15px;
    justify-content: center;
}
.form-align-center{
    justify-content: center;
}
.special-instructions{
    /* background-color: red; */
    min-height: min-content;
    border: 2px dotted black;
    padding: 0px 0px;
    margin: auto;
}

.modal-border{
  border: none !important;
}

.dateRange {
  width: 91%;
  height: 2.4rem;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  padding: 1rem;
  margin-left: 4%;
}

.dateRangeError {
  width: 91%;
  height: 2.4rem;
  border: 1px solid red;
  border-radius: 0.25rem;
  padding: 1rem;
  margin-left: 4%;
}