.page-item-boxes {
  margin: 0px 5px;
}
.page-item-contents {
  border-color: #d9d9d9 !important;
  color: black;
}
.active-item .page-item-contents {
  border-color: #1890ff !important;
  background-color: white !important;
  color: #1890ff !important;
}
