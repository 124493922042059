.container {
  max-width: 98% !important;
}

.blogs {
  margin-top: 5%;
  padding: 0px 40px;
}

.banner {
  margin-top: 10px;
}

.blog-section {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  min-height: 200px;
}

.blog-section .heading {
  font-family: sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  color: #000000;
  margin-bottom: 5%;
}

.blog-button {
  background: #1890ff;
  border: none;
}

.landing-page-left-content {
  font-family: sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 42px;
  vertical-align: middle;
  color: #ffffff;
  background-color: #1890ff;
  padding-top: 10% !important;
}

.landing-page-right-content {
  vertical-align: middle;
  padding: 0px !important;
}

@media only screen and (hover: none) and (pointer: coarse) {
  .banner {
    margin-top: 40px;
  }
  .blog-button {
    background: #1890ff;
    margin-bottom: 10px;
  }
  .blogs {
    margin-top: 5%;
    padding: 0px 20px;
  }
}

.image-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh; /* Full viewport height */
  width: 100vw; /* Full viewport width */
  overflow: hidden; /* Hide any overflow */
}

.center-cropped-image {
  width: 100%;
  height: 100%;
  object-fit: cover; /* Ensures the image covers the entire container */
}
