.table_row {
  text-align: center;
}
.table_data {
  padding-top: 10px;
  padding-bottom: 10px;
  text-align: center;
}
.user_modal {
  text-align: center;
}
.input_button {
  background-color: green;
  float: right;
  font-size: 16px;
  width: 100px;
  /* padding: unset; */
  color: white;
  margin: 1% 1% 1% 1%;
}

.user-form {
  padding: 10%;
}
.dropdown-list {
  background-color: white !important;
  width: 100% !important;
}
.user-modal-size {
  max-width: 50vw;
}

.button-cell {
  display: flex;
  flex-direction: row;
  align-items: center;

  flex: none;
  order: 5;
  flex-grow: 0;
  margin-top: 10%;
}

.button-cell .buttons {
  position: relative;
  width: 20px;
  margin: 5px;
}

.active-btn-and-checkbox-cell {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: 10%;
}

.active-btn-and-checkbox-cell .label {
  padding-left: 10%;
}
