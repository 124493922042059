.account_page{
    width: 100vw;
    height: 100vh;
    background-color: darkgray;
}
.tab-padding{
    padding: 3%;
}
.modal-size{
    max-width: 80vw;
}
.middle-content-top-margin{
    margin-top: 5%;
}
.info-section{
    /* padding-top: 2%; */
    margin: 0px 10px;   
}
.input-field{
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 8px 12px;

    position: static;
    width: 100%;
    height: 40px;
    left: 0px;
    top: 30px;

    /* Gray / gray-1 */

    background: #FFFFFF;
    /* Gray / gray-5 */

    border: 1px solid #D9D9D9;
    box-sizing: border-box;
    border-radius: 2px;

    /* Inside Auto Layout */

    flex: none;
    order: 1;
    align-self: stretch;
    flex-grow: 0;
    margin: 0px 0px;
}
.account-list-section{
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    position: static;
    /* Gray / gray-1 */

    background: #FFFFFF;
    /* Gray / gray-5 */
    /* Inside Auto Layout */

    flex: none;
    order: 0;
    flex-grow: 0;
}
.account-list-section-border{
    margin-top: 4%;
    border: 1px solid #D9D9D9;
    box-sizing: border-box;
    border-radius: 2px;
}
.account-list-row{
    width: 100%;
    justify-content: center;
}
.account-list-button{
    justify-content: center !important;
}
.search-bar{
    width: 250px;
    height: 40px;
}
.input_item {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: auto;
    background: #ffffff;
    border: 1px solid #d9d9d9;
    box-sizing: border-box;
    border-radius: 2px;
    flex: none;
    order: 1;
    align-self: stretch;
    flex-grow: 0;
    margin: auto;
  }
.row-margin{
    margin: 10px 0px;
}
.acc-table-row:hover{
    background-color:#e9efef;
    cursor: pointer;
}
.custom-row{
    
    padding: 10px;
    background: #FAFAFA;
    border: 1px solid #F0F0F0;
    margin: 25px 0px;
}

.custom-button-width{
    width: 90px !important;
    padding: 5px !important;
}

.series-button{
    margin-right: 1%;
    margin-left: 1%;
}

.custom-tab {
    display: inline-block;
    margin-right: 5px; /* Adjust as needed for spacing */
}

.nav-tabs {
    white-space: nowrap;
    overflow-x: auto;
    border: none !important;
    margin-bottom: 0px !important;
}

.nav-tabs .nav-item {
    flex-shrink: 0;
}
.nav-link.active {
    color: #0d6efd !important;
}
.nav-link{
    color: black;
    border: none !important;
}

.custom-tabs-container .nav-link.active {
    border: none !important;
    border-bottom: 2px solid #1890ff !important;
}

.custom-tabs-container .custom-tab-link {
    color: #000;
}

.tab-navigation {
    display: flex;
    justify-content: space-between;
    padding: 10px;
}
