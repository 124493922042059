.toolbar {
  background-color: rgb(255, 255, 255) !important;
  box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.15);
}
.navbar-position {
  justify-content: left;
  margin-left: 10px;
  background: #ffffff;
  width: 100%;
}

.navbar-position .nav-link {
  position: static;
  color: #262626;
}

.navbar-position .activeNow {
  color: #1890ff !important;
  border-bottom: 2px solid #1890ff !important;
  padding-bottom: 3px;
}

.toolbar .brand {
  position: static;

  font-family: sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 30px;
  color: #000000;
  margin: 0px 0px;
}

.login-button {
  margin-left: auto;
}

.login-button .user-icon {
  display: unset;
  width: 25px;
  margin-right: 10px;
}

.icon {
  width: 120px;
}

@media only screen and (hover: none) and (pointer: coarse) {
  .login-button {
    margin-left: 0px;
  }

  .login-button .user-icon {
    display: none;
  }
}
