.acc-table-row {
    padding: 10px 15px;
}

.mb-3 {
    margin-bottom: 1rem;
}

.align-items-center {
    align-items: center;
}

.buttons-style .input_button {
    margin: 5px;
}

.loader-custom {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}
